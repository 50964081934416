<template>
  <a
    href="https://voxusagers.numerique.gouv.fr/Demarches/2934?&view-mode=formulaire-avis&nd_mode=en-ligne-enti%C3%A8rement&nd_source=button&key=2c212af2116e6bf85d63fee0645f8a10"
    target="_blank"
    rel="noopener"
  >
    <img
      src="https://voxusagers.numerique.gouv.fr/static/bouton-blanc.svg"
      alt="Je donne mon avis"
      title="Je donne mon avis sur cette démarche"
    />
  </a>
</template>

<style scoped>
img {
  height: 55px;
}
</style>
