@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































































































































































































































.poster-contents {
  display: flex;
  flex-direction: column;
  height: 296mm;
  overflow: hidden; // to show how it will be on paper
  padding: 14mm;
  // Need to repeat some styling directly here for PDF generation
  font-family: "Marianne" !important;
  // copy vuetify styling to have on generated PDF
  p {
    margin-bottom: 0;
    font-size: 100%; // 16px
  }
  i {
    background: white;
  }
  h3 {
    font-size: 1.5em;
  }
}
.poster-contents.small {
  font-size: 90%;
}
.poster-contents.big {
  font-size: 110%;
}

.spacer {
  flex-grow: 1;
}

h2 {
  font-size: 26px;
  margin-bottom: 16px;
}

#heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  div {
    text-align: left;
  }
}

.canteen-image {
  max-width: 200px;
  max-height: 150px;
  margin-right: 10px;
}

#indicators {
  font-size: 0.875em;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 0.3em;
}

#hat {
  width: 150px;
  height: 112px;
  margin-left: 1em;
}

.badge-description {
  margin-left: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1em;
}

.pat {
  margin-bottom: 0 !important;
  margin-top: 8px;
}

#logos {
  width: 440px;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}

#quality-data {
  align-self: center;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 1em;
  width: 100%;
}

#quality-data > div {
  width: 100%;
}

.appro-box {
  text-align: center;
  border: solid 1px #ccc;
  width: 49%;
  padding: 0.625em;

  p {
    margin-bottom: 0.5em;
  }

  .percent {
    font-size: 1.5em;
    font-weight: 900;
    line-height: 1.8em;
    letter-spacing: normal;
    color: #464646;
    margin-right: 4px;
  }

  .appro-label {
    color: #464646;
    font-size: 0.9em;
    font-weight: 400;
    letter-spacing: 0.0333333333em;
    line-height: 1.25em;
  }
}

.appro-box.big {
  width: 60%;
  margin-top: 1em;
  height: 130px;

  .appro-label {
    font-size: 1em;
  }
}

#custom-text {
  overflow-wrap: break-word;
  hyphens: auto;
  margin-top: 8px;
}

#about {
  width: 100%;
  text-align: left;

  h3 {
    margin-bottom: 8px;
    font-size: 1em;
    font-weight: bold;
  }

  a {
    color: $ma-cantine-grey;
    font-weight: bold;
  }

  .footer-text {
    font-size: 0.875em;
    margin: 0;
    margin-top: 0.3em;
  }

  #qr-code {
    padding-top: 0.4em;
  }
}
.d-flex {
  display: flex;
}
.justify-space-between {
  justify-content: space-between !important;
}
.justify-center {
  justify-content: center !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.badge-heading {
  margin-bottom: 0.5em;
}
.poster-explainer {
  margin-top: 0.75em;
}
