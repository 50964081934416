/* GENERAL: https://vuetifyjs.com/en/api/vuetify/#sass-variables */
$border-radius-root: 12px;

/* FONT: https://vuetifyjs.com/en/api/vuetify/#sass-variables */
$body-font-family: 'Marianne', Arial, sans-serif;
$title-font: 'Marianne', Arial, sans-serif;

/* BUTTON: https://vuetifyjs.com/en/api/v-btn/#sass-variables */
$btn-text-transform: none;
$btn-letter-spacing: -0.011em;
$btn-font-weight: 500;
$btn-border-radius: 0; // DSFR: https://www.systeme-de-design.gouv.fr/elements-d-interface/composants/bouton/

/* CARD */
$card-title-word-break: normal !important;
$card-hover-elevation: 4;
$card-border-radius: 0; // DSRF: https://www.systeme-de-design.gouv.fr/elements-d-interface/composants/carte/

/* ALERT */
$alert-border-radius: 0; // DSFR

/* LEGACY COLORS */
$ma-cantine-orange: #EB5B25;
$ma-cantine-green: #61753f;
$ma-cantine-yellow: #E2A013;
$ma-cantine-light-yellow: #FBF1DC;
$ma-cantine-red: #C13331;
$ma-cantine-black: #000000;
$ma-cantine-grey: #333333;
$ma-cantine-dark-white: #E1E1E1;
$ma-cantine-white: #FFFFFF;

/* DSFR tabs */
$tab-text-transform: none;
$tab-font-size: 15.2px;
$tab-font-weight: 700;
$tab-line-height: 24px;
$tabs-bar-height: 40px;
