@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';






















.fr-skiplinks {
  top: 0;
  left: 0;
  position: absolute;
  padding: 1rem 0;
  background-color: #ddd;
  width: 100%;
  z-index: 1;
}

// focus-within not currently supported by IE but I think skiplinks are mostly helpful for people
// using screenreaders, which will read out the link whether or not it is visible on screen.
.fr-skiplinks:focus-within {
  z-index: 7;
}

.fr-skiplinks__list {
  display: flex;
}
