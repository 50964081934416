@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';










































































































































.box {
  width: fit-content;
}
