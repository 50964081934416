@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';




















.action {
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: left;

  span {
    padding-left: 10px;
    max-width: 90%;
  }
}
