@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';





#bio-logo {
  height: 2.9em;
}
