@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























.description-container {
  flex: 4;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  white-space: pre-wrap;
}
