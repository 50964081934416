@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

















































































































































































.v-tab {
  min-width: 30px;
  max-width: 50px;
}
.v-tabs-slider-wrapper {
  width: 50px !important;
}
