@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';













































































































































































































































































































































































































#en-tete {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 18, 0.16));

  // DSFR navigation
  nav {
    width: 100%;
    border-top: solid 1px #e0e0e0;
    height: 56px; // same height as toolbar extension
  }
}

.fr-nav__link {
  height: 100% !important;
}

.fr-nav__link-child {
  height: 48px;
  width: 100%;
  justify-content: left;
}
.mc-tab {
  height: 100%;
  line-height: 24px;
  text-transform: none;
}
.mc-active-tab {
  border-bottom: 2px solid;
  color: #000091;
}
.mc-active-item {
  border-left: 2px solid;
  border-left-color: currentColor !important;
  color: #000091;
}
button {
  color: inherit;
}
.stealth-active-tab {
  color: rgb(22, 22, 22) !important;
  caret-color: rgb(22, 22, 22);
  outline-color: #fff;
  text-decoration-color: #fff;
}
.quick-link.v-btn--active::before {
  opacity: 0;
}
.v-btn--active::before {
  opacity: 0; // get rid of the change in background colour
}
.v-btn--active:hover::before {
  opacity: 0.08;
}

#menu-quick-links {
  border-bottom: solid 1px #e0e0e0;
}

.menu-item {
  border-bottom: solid 1px #e0e0e0;
}
