@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';








































































ul {
  margin: 1em 0;
  padding: 0;

  li {
    margin: 1em 0;
  }
}

.label {
  min-height: 3em;
}

.label > img {
  height: 2.9em;
  margin-right: 0.5em;
  float: left;
}
