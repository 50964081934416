@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































































































































.number {
  font-weight: bold;
  color: #0c7f46;
  background-color: #fff;
  border: 1px solid #0c7f46;
  border-radius: 50%;
  padding: 0.3rem;
  height: 2.2rem;
  width: 2.2rem;
}
