@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

















































































































































#poster-preview {
  border: 1px solid $ma-cantine-grey;
  position: relative;
}
.poster-sizing {
  width: 210mm;
  min-width: 210mm;
  height: 296mm;
  min-height: 296mm;
}
@media (max-width: 210mm) {
  #poster-preview,
  #post-preview-button {
    display: none;
  }
}
