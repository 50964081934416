@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';











































p.help {
  color: $ma-cantine-grey;
  font-size: 0.95em;
}
