@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';



































































#diagnostic-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 500px;

  #submit {
    color: $ma-cantine-white;
    font-size: 24px;
    background-color: $ma-cantine-orange;
    width: 10em;
    padding: 0.2em;
    border-radius: 1em;
    cursor: pointer;
    margin-left: auto;
    text-align: center;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
