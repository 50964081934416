@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































































































































































































































































































































































































































































#content {
  width: 210mm;
}

#poster-form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px !important;
  margin: auto;
}

#poster-generation {
  display: flex;
  margin-top: 50px;
  width: 1200px;
}

.poster-sizing {
  width: 210mm;
  min-width: 210mm;
  height: 296mm;
  min-height: 296mm;
}

#poster-preview {
  border: 1px solid $ma-cantine-grey;
  position: relative;
}

.loading-overlay {
  background: #b0aeae4f;
  position: absolute;
}

@media (max-width: 1200px) {
  #poster-generation {
    flex-direction: column;
    width: auto;
  }
}

@media (max-width: 210mm) {
  #content {
    width: 100%;
  }

  #poster-preview {
    display: none;
  }
}
