@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';















































































#appro-graphs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0.6em;
    margin-left: 1em;
    font-size: 15px;
  }

  .graphs {
    border: 1px solid;
    border-radius: 0.5em;
    padding: 0.2em;
  }

  .good {
    border-color: $ma-cantine-green;
    margin-right: 0.2em;
  }

  .bad {
    border-color: $ma-cantine-red;
  }

  .graph {
    max-width: 50%;
  }
}

#legend {
  width: 11em;

  p {
    font-size: 0.8em;
  }

  .dot {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.3em;
  }

  .bio {
    background-color: #50b04e;
  }

  .durable {
    background-color: #fbbc04;
  }

  .other {
    background-color: #fff4ca;
  }
}

@media (max-width: 1120px) {
  #appro-graphs {
    flex-direction: column;

    .graphs {
      max-width: 25em;
    }
  }

  #legend {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
