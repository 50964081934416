@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';















































































































label {
  display: block;
}
select.fr-select {
  --grey-950-100: #eee;
  --grey-950-100-hover: #d2d2d2;
  --grey-950-100-active: #c1c1c1;
  --grey-200-850: #3a3a3a;
  --background-contrast-grey: var(--grey-950-100);
  --background-contrast-grey-hover: var(--grey-950-100-hover);
  --background-contrast-grey-active: var(--grey-950-100-active);
  --border-plain-grey: var(--grey-200-850);
  --text-default-grey: var(--grey-200-850);
  --idle: transparent;
  --hover: var(--background-contrast-grey-hover);
  --active: var(--background-contrast-grey-active);
  --data-uri-svg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23161616' d='m12 13.1 5-4.9 1.4 1.4-6.4 6.3-6.4-6.4L7 8.1l5 5z'/%3E%3C/svg%3E");
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #eee;
  background-color: var(--background-contrast-grey);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23161616' d='m12 13.1 5-4.9 1.4 1.4-6.4 6.3-6.4-6.4L7 8.1l5 5z'/%3E%3C/svg%3E");
  background-image: var(--data-uri-svg);
  background-position: calc(100% - 1rem) 50%;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: inset 0 -2px 0 0 #3a3a3a;
  box-shadow: inset 0 -2px 0 0 var(--border-plain-grey);
  color: #3a3a3a; // TODO: maybe chanage this to match other components (or change them to match this?)
  color: var(--text-default-grey);
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  width: 100%;
  // TODO: add 2px white border to match focus border look of existing components; fix height to be same as existing components
}
select.fr-error {
  box-shadow: inset 0 -2px 0 0 #df3232;
}
