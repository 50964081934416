// this file copies DSFR naming conventions

// Typography
// DSFR: https://www.systeme-de-design.gouv.fr/elements-d-interface/fondamentaux-de-l-identite-de-l-etat/typographie
.fr-h1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
  margin: 0 0 1.5rem;
}

.fr-h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin: 0 0 1.5rem;
}

.fr-h3 {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
  margin: 0 0 1.5rem;
}

.fr-h4 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  margin: 0 0 1.5rem;
}

.fr-h5 {
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin: 0 0 1.5rem;
}

.fr-h6 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin: 0 0 1.5rem;
}

// NB: not all typographies added yet, if the one you need isn't here add it
.fr-text-lg {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.fr-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.fr-text-sm {
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.fr-button { // https://www.systeme-de-design.gouv.fr/elements-d-interface/composants/bouton
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.fr-text-xs {
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.fr-display-xs {
  /* 0.Titres alternatifs/XS/Desktop */
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.5rem;
}

.fr-hint-text {
  color: #666;
  display: block;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.fr-label {
  color: #161616;
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
}
